<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
    persistent
  >
    <v-card
      outlined
      color="#fff"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-file-document-edit
        </v-icon>Update Tracking Instance
      </h2>
      <v-container fluid>
        <v-alert
          v-if="isInstanceFromPartner"
          text
          prominent
          icon="mdi-information-symbol"
          color="#01579B"
          border="left"
          class="authorization-alert"
        >
          EzyBuild does not support editing imported data. Please make changes to your Autodesk account and resync.
        </v-alert>
      </v-container>
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <v-container fluid>
          <v-card-text class="text-body-1 text-left">
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="instanceName"
                  class="field-style"
                  :rules="[(v) => !!v || 'This field is required']"
                  hint="Name for your reference:- 'Concrete Labor - First Floor'"
                  persistent-hint
                  label="Instance Name*"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="inputQuantity"
                  class="field-style"
                  label="Input Quantity*"
                  type="number"
                  :rules="[(v) => !!v || 'This field is required']"
                  hint="Total work hours required, eg - 300 Hrs"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="timeSheetPeriod"
                  class="field-style"
                  :items="periodsList"
                  label="Time Sheet Period*"
                  :rules="[(v) => !!v || 'This field is required']"
                  single-line
                  hint="Set time sheet upload frequency"
                  persistent-hint
                  item-value="id"
                  disabled
                  item-text="name"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="outputQuantity"
                  class="field-style"
                  label="Output Quantity*"
                  type="number"
                  :rules="[(v) => !!v || 'This field is required']"
                  hint="Total material quantity required, eg - 500 cy"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="outputUnit"
                  class="field-style"
                  :items="outputUnitsList"
                  label="Output Unit*"
                  :rules="[(v) => !!v || 'This field is required']"
                  single-line
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
          <p class="info-text">
            <span class="nb-text">Nb:- Input unit will be 'hr' by default</span> <br>
            *Indicates required field
          </p>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="#000"
              :disabled="isInstanceFromPartner"
              @click="updateTrackingItemInstance()"
            >
              Update
            </v-btn>
            <v-btn
              class="mt-2"
              color="#aaa"
              @click="showModal = false"
            >
              <span class="black--text">Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>

<script>
import Constants from 'src/constants';
import spinner from 'src/views/dashboard/component/SpinnerCentre';

export default {
 name: 'EditTrackingItemInstance',
  components: {
    'centre-spinner': spinner,
  },
  props: {
    trackingItemInstanceId: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      showModal: true,
      isFormValid: false,
      loading: false,
      periodsList: [
        {
          name: 'Daily',
          id: 'daily',
        },
        {
          name: 'Weekly',
          id: 'weekly',
        },
        {
          name: 'Monthly',
          id: 'monthly',
        },
      ],
      instanceName: '',
      inputQuantity: null,
      timeSheetPeriod: 'daily',
      outputQuantity: null,
      outputUnit: '',
      outputUnitsList: ['cf', 'ci', 'cy', 'ea', 'ft', 'ft-in', 'gal', 'in', 'lb', 'ls', 'mi', 'oz', 'pt', 'qt', 'sf', 'si', 'sy', 't(s)', 'yd'],
      isPeriodAlreadySet: false,
      isInstanceFromPartner: false,
    };
  },
  computed: {
    trackingItemInstanceDetails () {
      return this.$store.getters['projects/getTrackingItemInstanceDetails'];
    },
  },
  watch: {
    showModal: function (newval, oldval) {
      if (newval === false) {
        this.$emit('closed');
      }
    },
  },
  async mounted () {
    await this.getTrackingItemInstanceDetails();
  },
  methods: {
    async getTrackingItemInstanceDetails () {
      this.loading = true;
      await this.$store.dispatch('projects/fetchTrackingItemInstanceDetails', {
        trackingItemInstanceId: this.trackingItemInstanceId,
      }).then(response => {
       this.instanceName = this.trackingItemInstanceDetails.name;
       this.inputQuantity = this.trackingItemInstanceDetails.input_quantity;
       this.outputQuantity = this.trackingItemInstanceDetails.output_quantity;
       this.outputUnit = this.trackingItemInstanceDetails.output_unit;
       this.timeSheetPeriod = this.trackingItemInstanceDetails.time_sheet_period;
        if (this.trackingItemInstanceDetails.database === false) {
         this.isInstanceFromPartner = true;
          this.loading = false;
          return;
        }
        this.isInstanceFromPartner = false;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    async updateTrackingItemInstance () {
      if (this.$refs.form.validate() === false) {
        this.$store.dispatch('alert/onAlert', {
          message: 'Please fill the required fields before proceeding.',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
        return;
      }
      this.loading = true;
      await this.$store.dispatch('projects/updateTrackingItemInstance', {
        updateDetails: {
          name: this.instanceName,
          input_quantity: this.inputQuantity,
          time_sheet_period: this.timeSheetPeriod,
          input_unit: 'hr',
          output_quantity: this.outputQuantity,
          output_unit: this.outputUnit,
        },
        trackingItemInstanceId: this.trackingItemInstanceId,
      }).then(response => {
        this.$store.dispatch('alert/onAlert', {
        message: 'Tracking item instance updated successfully.',
        type: Constants.ALERT_TYPE_SUCCESS,
      });
        this.loading = false;
        this.$emit('success');
        this.showModal = false;
      }).catch(() => {
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 21px;
  margin-bottom: 10px;
}
.icon-style {
  color: #37474F;
  font-size: 55px;
  margin-right: 5px;
}
.field-style {
  margin-top: -10px;
}
.hrw-checkbox {
  margin-top: -30px;
}
.info-text {
  margin-left: 10px;
}
.nb-text {
  color: #37474F;
  font-style: italic;
  font-family: 'Times New Roman', Times, serif;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 16px;
  margin-bottom: -2px;
  padding-bottom: 10px;
  padding-top: 10px;
}
</style>
